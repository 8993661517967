import Translation from '../user/Translation'
function t(field) { return Translation.get(field) }


var UserProfile = (function() {

  var getProfile = function() {
    return window.sessionStorage.getItem("user_name")
  }
  var getUserLanguage = function() {
    return window.sessionStorage.getItem("user_language")
  }
  var getUserLevel = function() {
    if (parseInt(window.sessionStorage.getItem("user_level")) === 7) return 3
    else return parseInt(window.sessionStorage.getItem("user_level"))
  }
  var getId = function() {
    return window.sessionStorage.getItem("user_id")
  }
  var getCustomer = function() {
    return window.sessionStorage.getItem("customer_id")
  }
  var getServiceRobotID = function() {
    return window.sessionStorage.getItem("service_robot_id")
  }
  var getServiceReportID = function() {
    return window.sessionStorage.getItem("service_report_id")
  }
  var getAboLeft = function() {
    let left = window.sessionStorage.getItem("abo_time")
    if (left && left !== 'undefined') {
      var year = left.substring(0, 4),
      month = left.substring(6, 7),
      day = left.substring(9, 10);
      let d = new Date()
      let monthNow = '' + (d.getMonth() + 1)
      let dayNow = '' + d.getDate()
      let yearNow = d.getFullYear()
      d.setDate(d.getDate() + 30)
      let month1 = '' + (d.getMonth() + 1)
      let day1 = '' + d.getDate()
      let year1 = d.getFullYear()
      if (parseInt(year)*10000+parseInt(month)*100+parseInt(day) < parseInt(yearNow)*10000+parseInt(monthNow)*100+parseInt(dayNow)) return <div className="customer abo">{t("customer_abo_disabled")} {left}</div>
      else {
        if (parseInt(year)*10000+parseInt(month)*100+parseInt(day) > parseInt(year1)*10000+parseInt(month1)*100+parseInt(day1)) return null
        else return <div className="customer abo">{t("customer_abo_left")} {left}</div>
      }
    }
    else return null
  }
  var checkRight = function (right) {
    const userRights = window.sessionStorage.getItem("user_rights")
    if (userRights.includes(right + ";")) return true
    else return false
  }
  var showFeeds =  function (right) {
    return window.sessionStorage.getItem("show_feeds")
  }

  var setProfile = function(result, name) {
    //console.log("set storage", result)
    window.sessionStorage.setItem("animalgroup_id", 0)
    window.sessionStorage.setItem("user_id", result.id)
    window.sessionStorage.setItem("user_name", name)
    window.sessionStorage.setItem("user_language", result.language_id)
    window.sessionStorage.setItem("user_level", result.userlevel_id)
    window.sessionStorage.setItem("user_rights", result.right)
    window.sessionStorage.setItem("customer_id", result.customer_id)
    window.sessionStorage.setItem("customer_name", result.customer_name)
    window.sessionStorage.setItem("show_feeds", result.show_feeds)
    window.sessionStorage.setItem("num_robots", result.num_robots)
    window.sessionStorage.setItem("robot_types", result.robot_types)
    window.sessionStorage.setItem("robot_ids", result.robot_ids)
    window.sessionStorage.setItem("robot_names", result.robot_names)
    window.sessionStorage.setItem("support", result.support)
    window.sessionStorage.setItem("fontSize", result.fontsize)
    window.sessionStorage.setItem("milkcows", result.milkcows)
    window.sessionStorage.setItem("fattening", result.fattening)
    window.sessionStorage.setItem("abo_time", result.abo_time)
    if (result.feeder_id) {
      window.sessionStorage.setItem("robot_id", result.feeder_id)
      window.sessionStorage.setItem("robot_name", result.feeder_name)
      window.sessionStorage.setItem("robot_load", result.feeder_load * 0.9)
    }
    var ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1) {
      if (ua.indexOf('chrome') > -1) window.sessionStorage.setItem("browser", "Chrome")
      else window.sessionStorage.setItem("browser", "Safari")
    }
  }
  var clearProfile = function() {
    window.sessionStorage.clear()
    window.localStorage.clear()
  }
  var setFont = function(size) {
    window.sessionStorage.setItem("fontSize", size)
  }
  //var setServiceRobotID = function(id) {
  //  window.sessionStorage.setItem("service_robot_id", id)
  //}
  var setServiceReport = function(robotID, id) {
    window.sessionStorage.setItem("service_robot_id", robotID)
    window.sessionStorage.setItem("service_report_id", id)
  }
  var isSafari = function() {
    if (window.sessionStorage.getItem("browser") === "Safari") return true
    else return false
  }
  var checkSupport = function () {
    if (window.sessionStorage.getItem("support") === "true") return true
    else return false
  }
  var checkMilk = function () {
    return window.sessionStorage.getItem("milkcows")
  }
  var checkMeat = function () {
    return window.sessionStorage.getItem("fattening")
  }

  return {
    getName: getProfile,
    getId: getId,
    getUserLevel: getUserLevel,
    checkRight: checkRight,
    getUserLanguage: getUserLanguage,
    setProfile: setProfile,
    setFont: setFont,
    clearProfile: clearProfile,
    getCustomer: getCustomer,
    showFeeds: showFeeds,
    checkSupport: checkSupport,
    isSafari: isSafari,
    checkMilk: checkMilk,
    checkMeat: checkMeat,
    getAboLeft: getAboLeft,
    setServiceReport: setServiceReport,
    //setServiceRobotID: setServiceRobotID,
    getServiceRobotID: getServiceRobotID,
    getServiceReportID: getServiceReportID,
  }

})()

export default UserProfile