import TableFunctions from './api/TableFunctions.js'
import ReactTable from "react-table-6"
import AlertDialog from "../user/Dialog.js"
import Draggable from "react-draggable"
import Translation from '../user/Translation'
import UserProfile from '../user/UserProfile'
import ManageCols from "./api/ManageFields"
import GetJsonFormPHP, { PostJsonFormPHP } from "./api/GetJsonFormPHP"
import {setTableFontSize} from './api/imports.js'
import Info from "./api/Info"
import Select from 'react-select'
import Barcode from 'react-barcode';
import dateFormat from 'dateformat'
import './styles/react-table.css'
import imgFalse from './img/false.png'
import imgTrue from './img/true.gif'
import imgLabel from './img/label.png'
import imgAttention from './img/attention.png'

function t(field) { return Translation.get(field) }

class Etikett extends TableFunctions {
  constructor() {
    super();
    this.state = {
      geturl: "/robots/get_robotservices.php?robot_id=",
      saveurl: "/robots/set_robots.php",
      robot: null, types: null, sn: "", createSN: false,
    }
    this.handleClose = this.handleClose.bind(this)
    //this.calcFromString = this.calcFromString.bind(this)
  }
  handleClose() {
    this.props.closeDiv();
  }
  reload() {
    this.props.reload();
  }
  static getDerivedStateFromProps(props, state) {
    let serial_number = props.row.serial_number
    let createSN = false
    const strToArr  = value => {
      let arr = value.match(/[0-9a-f]{2}/ig); // convert into array of hex pairs
      arr = arr.map(x=> parseInt(x, 16)); // convert hex pairs into ints (bytes)
      return arr;
    }
    const calculateCRC8  = value => {
      let CRC8_DATA = '005EBCE2613FDD83C29C7E20A3FD1F419DC3217FFCA2401E5F01E3BD3E6082DC237D9FC1421CFEA0E1BF5D0380DE3C62BEE0025CDF81633D7C22C09E1D43A1FF4618FAA427799BC584DA3866E5BB5907DB856739BAE406581947A5FB7826C49A653BD987045AB8E6A7F91B45C6987A24F8A6441A99C7257B3A6486D85B05E7B98CD2306EEDB3510F4E10F2AC2F7193CD114FADF3702ECC92D38D6F31B2EC0E50AFF1134DCE90722C6D33D18F0C52B0EE326C8ED0530DEFB1F0AE4C1291CF2D73CA947628ABF517490856B4EA6937D58B5709EBB536688AD495CB2977F4AA4816E9B7550B88D6346A2B7597C94A14F6A8742AC896154BA9F7B6E80A54D7896B35';
      CRC8_DATA = strToArr(CRC8_DATA);
      var i = 1;
      var i2 = value.length - 1;
      var b = 0;
      while (i <= i2) {
        b = CRC8_DATA[(b ^ value[i]) & 255];
        i++;
      }
      return b;
    }
    if (serial_number === "") {
      //console.log(props.robotTypes)
      let netxSerial = props.robotTypes[props.row.robottype_id].next_serial
      if (netxSerial < 100) netxSerial = "0" + netxSerial
      if (netxSerial < 10) netxSerial = "0" + netxSerial
      serial_number = props.robotTypes[props.row.robottype_id].serial_number + dateFormat(props.row.deliver_date, "yy") + netxSerial +'-'
      let byte_array = strToArr(serial_number);
      let checksum = calculateCRC8(byte_array)
      if (checksum < 100) serial_number += "0"
      if (checksum < 10) serial_number += "0"
      serial_number += checksum
      createSN = true
    }
    return {
      robot: props.row,
      types: props.robotTypes,
      sn: serial_number,
      createSN: createSN,
    }
  }
  render() {
    const printWindow = value => {
      //let pri = document.getElementById("ifmcontentstoprint").contentWindow;
      if (this.state.createSN) {
      let row = this.state.robot
      row.serial_number = this.state.sn
        PostJsonFormPHP(this.state.saveurl, row).then((result) => {
          this.reload()
        })
      }
      let print_content = document.getElementById("divcontents");
      let print_window = window.open('', '_blank');
      print_window.document.open();
      print_window.document.write("<html><head><style>@media print { @page { margin: 0.1in; } body { width: 2.7in; max-height: 1.25in; padding: 8px; border: 1px solid; background: rgba(255, 255, 255, 1); } table {font-weight: bold; font-size: 0.8em;} .print_img_top { width: 1.30in; } .print_img_ce { width: 0.6in; } svg {max-width: 2.3in; margin: 0px; padding:0px;}} </style></head><body>" + print_content.innerHTML + "</body></html>");
      print_window.document.close();
      print_window.print();
    }
    const robotType = this.state.types[this.state.robot.robottype_id].name //"Aranom Roboter"
    let bj = dateFormat(this.state.robot.deliver_date, t('dateformat'))
    bj = bj.substring(3, 11)
    //const serial = this.state.
    return (
      <Draggable handle=".handle">
      <div className="edittable">
        <div className="handle"><div className="header"><button onClick={printWindow}>Drucken</button> <img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div></div>
        <div id="divcontents" class="etikett_print">
          <table width="100%" class="etikett_table" border="0" cellpadding="0" cellspacing="0">
            <tr><td width="10%">Typ:</td><td width="65%">{robotType}</td><td rowspan="2" colspan="2" valign="top" align="right"><img src="https://connect.hetwin.at/public/img/Hetwin_Logo.png" alt="" width="250px" class="print_img_top" /></td></tr>
            <tr><td>BJ:</td><td>{bj}</td></tr>
            <tr><td>VPN:</td><td> &nbsp; {this.state.robot.ip}</td><td width="20%">&nbsp;</td><td>&nbsp;</td></tr>
            <tr><td valign="center">SN: </td><td colspan="3"><Barcode value={this.state.sn} format="CODE128" width="1" height="25" marginTop="3" marginBottom="0" fontSize="15" /></td></tr>
            <tr><td colspan="3">Anschlussleistung: 50kW</td><td align="right" valign="top"><img src="https://connect.hetwin.at/public/img/CE.png" alt="" class="print_img_ce" width="90px" /></td></tr>
          </table>
        </div>
      </div>
      </Draggable>
    )
  }
}

class RobotList extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/robots/get_robots.php",
      saveurl: "/robots/set_robots.php",
      setTableUrl: "/users/change_tablesize.php",
      orgData: [], data: [], empty: null, selected: null, selectedRow: null, // Table data
      customers: null, robottypes: null, // Select input options
      showHelp: false, showHelpId: null, // Info popup
      showLabel: false, // Etikett drucken
      confirmVisible: false, title: null, message: null, choise: true, yesNo: [t('no'), t('yes')],  // OK popup and Error Messages
      robotFilter: 0, customerFilter: 0, statusFilter: true, customerSelect: null, typeSelect: null,  // Filter Options
      fontSize: setTableFontSize(),
      showfields: null, fieldWidths: null, manageFields: false, tablePageSize: 0, // Show table fields
    }
  }

  reload() {
    this.componentDidMount()
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({ empty: result.empty, })
      if (result.data === null) result.data = [this.state.empty]
      let pageSize = 25
      if (result.fieldwidth && result.fieldwidth._pageSize) pageSize = result.fieldwidth._pageSize
      let data = result.data
      data = this.setfilter(null, true, data)
      this.setState({
        orgData: result.data,
        data: data,
        customers: result.customers,
        robottypes: result.robottypes_list,
        robotType: result.robottypes,
        customerSelect: result.customerselect,
        typeSelect: result.typeselect,
        feederSelect: result.feederselect,
        showfields: result.showfields,
        fieldWidths: result.fieldwidth,
        tablePageSize: pageSize,
      })
    })
  }
  radioboxCell(initialValue, id, row, options) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    if (this.state.selected === row._index) {
      return (
        <div>
        <label><input type="radio" name={id} value="1" className="checkbox" checked={row.enabled === "1"} onChange={inputChange} />{options[1]}</label><br />
        <label><input type="radio" name={id} value="0" className="checkbox" checked={row.enabled === "0"} onChange={inputChange} />{options[0]}</label>
        </div>
      )
    }
    else {
      if (initialValue === "1") return (<div width="100%" align="center"><img src={imgTrue} alt={t("enabled")} title={t("enabled")} /></div>)
      else return (<div width="100%" align="center"><img src={imgFalse} alt={t("disabled")} title={t("disabled")} /></div>)
    }
  }
  setfilter(value, newstate=true, orgData = this.state.orgData) {
    let filter1 = this.state.customerFilter
    let filterRobot = this.state.robotFilter
    let filter3 = this.state.statusFilter
    if (value && value.name === "filter_customers") filter1 = value.value
    if (value && value.name === "filter_robots") filterRobot = value.value
    if (value && value.name === "filter_status") filter3 = !this.state.statusFilter
    let  res = orgData
    let a =[]
    if (filter3) {
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.last_update !== null || t.id === -1) a = [...a, t] })
        res = a
    }
    //else res = this.state.orgData
    a = []
    if (filter1 > 0) {
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.customer_id === parseInt(filter1) || t.id === -1 || t.trader_id === parseInt(filter1) || t.importer_id === parseInt(filter1))  a = [...a, t] })
      res = a
    }
    a = []
    if (filterRobot > 0) {
      // eslint-disable-next-line array-callback-return
      res.map((t,k) => { if (t.robottype_id ===  parseInt(filterRobot) || t.id === -1) a = [...a, t] })
      res = a
    }
    //else a2 = a
    //console.log(a1)
    if (value !== null && newstate) this.setState({
      data: res,
      robotFilter: filterRobot,
      customerFilter: filter1,
      statusFilter: filter3,
    })
    //console.log(filter3, this.state.statusFilter)
    return res
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const suRight = UserProfile.checkRight("superuser")
    const supportRight = UserProfile.checkRight("support")
    const traderRight = UserProfile.checkRight("trader")
    const subtraderRight =  UserProfile.checkRight("addcust") || UserProfile.checkRight("service")
    const userRight = UserProfile.checkRight("custuser")
    const w = this.state.fieldWidths
    //console.log(w)
    const columns = [
      { accessor: 'id',
        show: supportRight,
        Header: 'ID',
        width: (w && w['id'] ? w['id'] : 2)*em,
        getProps: (state, rowInfo) => ({ style: { textAlign: 'right', } }),
      },
      { accessor: 'online', show: false,},
      { accessor: 'trader_id', show: false,},
      { accessor: 'importer_id', show: false,},
      this.addSelectCell("customer", "customer_id", this.state.customerSelect, (w && w['customer_id'] ? w['customer_id'] : 0), subtraderRight, null, null, null, supportRight, false, false, null, false, false, '', false),
      this.addSelectCell("robottype_name", "robottype_id", this.state.robottypes, (w && w['robottype_id'] ? w['robottype_id'] : 0), true, null, null, false, suRight, false, false, null, false, true),
      //this.addSelectCell("robottype_multi", "multi", this.state.robottypes, 0, true, null, null, false, suRight),
      this.addSelectCell("robottype_multi", "multi", this.state.yesNo, (w && w['multi'] ? w['multi'] : 3)*this.state.fontSize, this.state.showfields ? this.state.showfields['multi'] && traderRight : traderRight, "center", null, false, traderRight, false, false, null, true),
      //this.addTextCell('robot_id_name', 'id_name', 0, (w && w['id_name'] ? w['id_name'] : 12)*this.state.fontSize, supportRight, "", null),
      this.addTextCell('ip', 'ip', 4, (w && w['ip'] ? w['ip'] : 7), this.state.showfields ? this.state.showfields['ip'] && suRight : suRight, "", 'right'),
      //this.addTextCell('ip_wlan', 'ip_wlan', 2, 6.5, suRight, "", 'right', this.getColor('cellAdmin')),
      this.addTextCell('robotname', 'name', 0, (w && w['name'] ? w['name'] : 0)),
      this.addTextCell('robot_serial_number', 'serial_number', 0, (w && w['serial_number'] ? w['serial_number'] : 12)*this.state.fontSize, this.state.showfields ? this.state.showfields['serial_number'] && traderRight : traderRight, "", null),
      //this.addTextCell('stable', 'stable', 0, 7),
      //this.addDateCell('order_date', 'order_date', false, 8, true, "right"),
      this.addDateCell('robots_ibn_date', 'deliver_date', false, (w && w['deliver_date'] ? w['deliver_date'] : 8)*this.state.fontSize,  this.state.showfields ? this.state.showfields['deliver_date'] : true, "right", null, null, traderRight),
      this.addDateCell('last_service', 'last_service_date', false, (w && w['last_service_date'] ? w['last_service_date'] : 8)*this.state.fontSize,  this.state.showfields ? this.state.showfields['last_service_date'] : true, "right", null, null, traderRight),
      //this.addDateCell('next_service', 'next_service_date', false, 8, true, "right", null, null, false),
      //this.addTextCell('§' + t('last_service') + " [h]", 'last_service_h', 1, 6, true, "", null, null, null, traderRight),
      //this.addTextCell('robot_operation_hours', 'operation_hours', 1, 6, true, "", null, null, null, traderRight),
      //this.addTextCell('robot_firmware_version', 'firmware_version', 0, (w && w['firmware_version'] ? w['firmware_version'] : 5)*this.state.fontSize, supportRight, "", "center", null, null, false),
      this.addTextAreaCell('robot_firmware_version', 'firmware_version', w && w['firmware_version'] ? w['firmware_version'] : 0, 0, this.state.showfields ? this.state.showfields['firmware_version'] && supportRight : supportRight, null, null, null, false, false, 0.8),
      this.addTextAreaCell('info', 'info', w && w['info'] ? w['info'] : 0, 0, this.state.showfields ? this.state.showfields['info'] : true, null, null, null, subtraderRight, false, 0.8),
      //this.addCheckboxCell('robot_inactive', 'inactive', 4, traderRight, )
      //this.addSelectCell("robottype_feeder", "feeder_id", this.state.feederSelect, 0, true, null, null, false, true),
      {
        Header: t("robottype_feeder"),
        accessor: "feeder_id",
        show: this.state.showfields ? this.state.showfields['feeder_id'] && supportRight : supportRight,
        Cell: ({value: initialValue, column: { id }, row}) => {
          let edit = false
          if ((row.robottype_id === 1 || row.robottype_id === 3 || row.robottype_id === 4) && (supportRight || UserProfile.checkSupport())) edit = true   // Aranom, Aramis I + II
          return this.selectCell(id , row, initialValue, this.state.feederSelect, true, edit, null ? initialValue : null, null, false)
        },
        width: (w && w['feeder_id'] ? w['feeder_id'] : 4)*em,
        getProps: (state, rowInfo) => ({ style: { textAlign: null, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : null } }),
        filterable: false,
      },
      this.addSelectCell("robot_active", "active", this.state.yesNo, 4, this.state.showfields ? this.state.showfields['active'] : true, "center", null, false, traderRight, false, false, null, true),
      this.addTextAreaCell('robot_accus', 'accus', w && w['accus'] ? w['accus'] : 0, 0, this.state.showfields ? this.state.showfields['accus'] && supportRight : supportRight, null, null, null, true, false, 0.8),
      this.addTextCell('robot_num_accus', 'num_accus', 1, (w && w['num_accus'] ? w['num_accus'] : 2), this.state.showfields ? this.state.showfields['accus'] && supportRight : supportRight, "", 'center'),
      //this.addSelectCell("robots_new", "new", this.state.yesNo, 4, supportRight, "center", null, false, false, false, false, null, true, true),
      this.addTextCell('robot_last_status', 'last_update', 0, (w && w['last_update'] ? w['last_update'] : 5)*this.state.fontSize, supportRight, "", "center", null, null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: supportRight ? 10.8*em : 8.2*em ,
        filterable: false,
        resizable: false,
        sortable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const showLabels = value => {
            this.setState({
              selectedRow: this.state.data[row._index],
              showLabel: true,
            })
          }
          let img = null
          let text = ""
          if (row.customer_id !== 1 && (row.robottype_id === 1 || row.robottype_id === 3 || row.robottype_id === 4) && row.feeder_id <= 0) {
            img = imgAttention
            text = "*Futterküche fehlt!"
          }
          return (
            <div>
              {img !== null ? <img src={img} alt={text} title={text}/> : null}
              {this.edit(row, supportRight, suRight, userRight)}
              {suRight ? <img src={imgLabel} alt="Label" title="Etikett drucken"  onClick={showLabels}/> : null}
            </div>
          )
        },
      }
    ]
    return columns
  }
  render() {
    //console.log("render", this.state)
    const columns = this.createColumns()
    const closeEditCols = value => {this.setState({ manageFields: false}); this.componentDidMount()}
    const supportRight = UserProfile.checkRight("support")
    const traderRight = UserProfile.checkRight("trader")
    const userRight = UserProfile.checkRight("custuser")
    const selectChange  = value => { this.setfilter(value) }
    const changeColumnsWidth = (width, name) => {
      if (name.length<100) {
        const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
        GetJsonFormPHP(this.state.setTableUrl + "?table=robot&col=" + name + "&width=" + width + "&em=" + em).then((result) => {
        })
      }
    }
    const dot = (color = 'transparent') => ({
      alignItems: 'center',
      display: 'flex',
      ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: 10,
        width: 10,
      },
    })
    const colourStyles = {
      control: (styles) => ({ ...styles, height: 28, minHeight: 28, }),
      menuList: (provided, state) => ({ ...provided, paddingTop: 0, }),
      menu: base => ({ ...base, marginTop: 0, }),
      container: (base) => ({ ...base, display:'inline-block', }),
      indicatorContainer: (styles) => ({ ...styles, padding: 0, margin: 0, }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color
        return {
          ...styles,
          backgroundColor: isDisabled ? undefined
            : isFocused ? color //  color.alpha(0.1).css()
            : isSelected ? color //data.color
            : undefined,
          color: isDisabled ? '#ccc'
            : isFocused  ? 'white'
            : isSelected ? 'white'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? isSelected ? data.color : color  //color.alpha(0.3).css()
              : undefined,
          },
          paddingTop: 2,
          paddingBottom: 2,
        };
      },
      input: (styles) => ({ ...styles, ...dot() }),
      //placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
    }
    let data = this.state.data
    if (this.state.robotFilter)  data = this.setfilter({name: "filter_robots", value: this.state.robotFilter}, false)
    if (this.state.customerFilter) data = this.setfilter({name: "filter_customers", value: this.state.customerFilter}, false)
    return (
      <div className={supportRight ? "": "table_100"}>
        <div className="table_buttons">
        { traderRight ? <button onClick={() => {this.insertRow(); }}>{t("addline")}</button> : null }
          { traderRight && this.state.orgData.length > 2 ? <span>
            {t('filter_customer')}:&nbsp;
            <Select
              options={this.state.customerSelect}
              styles={colourStyles}
              isSearchable={true}
              isClearable={true}
              className="chartselect"
              onChange={(value) => {selectChange({name: "filter_customers", value: value !== null ? value.value : 0})}}
              placeholder={t('select')}
            />
            {t('filter_robots')}:&nbsp;
            <Select
              options={this.state.typeSelect}
              styles={colourStyles}
              isSearchable={true}
              isClearable={true}
              className="chartselect"
              onChange={(value) => {selectChange({name: "filter_robots", value: value !== null ? value.value : 0})}}
              placeholder={t('select')}
            />
            <input id="remove_inactive" type="checkbox" defaultChecked={this.state.statusFilter}  onChange={() => {selectChange({name: "filter_status", value: null})}}/>{t("filter_status")}
          </span> : null }
          {supportRight ? <span> &nbsp; <button onClick={() => {this.setState({ manageFields: true, })}}>{t("show_advanced")}</button></span> : null}
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_robots_table",}) } } onMouseOver={(e) => {e.currentTarget.src=require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src=require("./img/info.png")}} /></div>
        </div>
        {this.state.tablePageSize > 0 ?
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          data={data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {this.state.tablePageSize}
          pageSizeOptions = {[10, 25, 50, 100]}
          useFlexLayout = {true}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
              onDoubleClick: (e, t) => {
                if (rowInfo.index !== this.state.selected && userRight) { this.setState({ selected: rowInfo.index, }) }
              },
              style: {
                background: rowInfo && rowInfo.index === this.state.selected ? 'rgba(234, 234, 255, 1)' : rowInfo.row.active === 1 ? this.getRobotColor(rowInfo.row.robottype_id) : null,
                color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.customer_id === 1 ? this.getColor('production') : rowInfo.row.customer_id === 2 ? "darkgrey" : rowInfo.row.active === 1 ? rowInfo.row.online === 0 && rowInfo.row.last_update ? '#DD0000' : 'black' : "darkgrey"
              }
            }
          }}
          getTheadTrProps={(state, column, instance) => {
            return {
              onClick: (e) => {if (!UserProfile.isSafari()) changeColumnsWidth(e.target.offsetParent.style.width, e.target.offsetParent.innerText); },
            }
          }}
          getProps = {(state, column, instance) => {
            return {
              onChange:  (e) => { if (e.target.type !== "number" && e.target.type !== "text" && e.target.name === "") changeColumnsWidth(e.target.value, "_pageSize") },
            }
          }}
        />
        </div>
        :null }
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        {this.state.showLabel && <Etikett row = {this.state.selectedRow} robotTypes = {this.state.robotType} closeDiv={() => { this.setState({ showLabel: false, }) }} reload={() => { this.setState({ showLabel: false, }); this.componentDidMount(); }}/>}
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        {this.state.manageFields && <ManageCols showfields={this.state.showfields} closeDiv={closeEditCols} page={"robot"}/>}
      </div>
    )
  }
}
export default RobotList;
