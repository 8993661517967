import TableFunctions from './api/TableFunctions.js'
import ReactTable from "react-table-6"
import Draggable from "react-draggable"
import AlertDialog from "../user/Dialog.js"
import TextField from '@mui/material/TextField'
import dateFormat from 'dateformat'
import Select from 'react-select'
import GetJsonFormPHP from "./api/GetJsonFormPHP"
import Info from "./api/Info"
import ManageCols from "./api/ManageFields"
import UserProfile from '../user/UserProfile'
import Translation from '../user/Translation'
import getStatusVars, {getStatusColors, setTableFontSize} from './api/imports.js'
import './styles/react-table.css'
import imgAttention from './img/attention.png'
import imgTrue from './img/true.gif'
import imgDetail from './img/editdetail.png'
import imgArrow from './img/arrow_right.png'
import imgMilk from './img/milk.png'
import imgBull from './img/bull.png'
import imgCalve from './img/calve.png'
import imgDrycows from './img/nomilk.png'
import imgTrash from './img/trash.png'
import imgWarning from './img/warning.png'
import imgWarnOnline from './img/careful.png'
import imgOk from './img/ok.png'
import imgInstall from './img/install.png'
import imgFalse from './img/false2.png'

function t(field) { return Translation.get(field) }

class Edit extends TableFunctions {
  constructor() {
    super()
    this.state = {
      geturl: "/robots/get_drives.php?type=1",
      saveurl: "/robots/set_drives.php?robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      eventUrl: "/robots/set_event.php",
      row: null, data: null, empty: null, selected: null, // Table data
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      event: -1, extradrive: 100,  date: dateFormat(new Date(), "yyyy-mm-dd"), events: ["*Zusatzfahrt", "*Klauenpflege", "*Freigang", "*Zurückwiegen"], delEvent: null, // event data
      edit: false,
      eventList: null,
      maxDrives: 10,
      position: [],
      fontSize: setTableFontSize(),
    }
    this.handleClose = this.handleClose.bind(this)
    this.handleEventDelete = this.handleEventDelete.bind(this)
  }
  reload(newId) {
    //console.log(this.state.id, newId)
    let id = this.state.id
    if (newId) id = newId
    GetJsonFormPHP(this.state.geturl + "&id=" + id).then((result) => {
      if (result.data === null) result.data = [this.state.empty]
      if (result.events === null) result.events = []
      this.setState({
        data: result.data,
        selected: null,
        eventList: result.events,
      })
    })
    this.props.reload(newId)
  }
  handleClose() {
    this.props.closeDiv();
  }
  static getDerivedStateFromProps(props, state) {
    if (state.id !== props.row.id) {
      let empty = props.emptydrives
      //console.log(empty)
      empty.animalgroup_id = props.row.id
      //empty.type = 1
      let drives = props.row.drives
      //console.log(props.emptydrives)
      if (!drives || drives === null) drives = [empty]
      //console.log(drives)
      return {
        index: props.index,
        id: props.row.id,
        row: props.row,
        data: drives,
        empty:  empty,
        selected: null,
        eventList: props.row.events,
        maxDrives: props.maxDrives,
        position: props.position,
      }
    }
    return null
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_window")
    e1.style.left = (window.scrollX+10*em)+'px'
    e1.style.top =(window.scrollY+5*em)+'px';
    //e1.style.left = (this.state.position[0]-28*em)+'px';
    //e1.style.top =(this.state.position[1]-2*em)+'px';
  }
  storeEvent(event) {
    event.preventDefault();
    GetJsonFormPHP(this.state.eventUrl + "?id="+this.state.row.org_animalgroup_id+"&evid=" + this.state.event + "&val=" + this.state.extradrive + "&date=" + this.state.date).then((result) => {
      this.setState({
        extradrive: 100,
        event: -1,
        date: dateFormat(new Date(), "yyyy-mm-dd"),
      })
      this.reload()
    })

  }
  handleEventDelete = (id) =>{
    GetJsonFormPHP(this.state.eventUrl +"?delID=" + id).then((result) => {
      this.reload()
    })
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const supportRight = UserProfile.checkRight("support")
    const userRight = UserProfile.checkRight("custuser")
    let addRight = userRight
    if (this.state.data.length >= this.state.maxDrives) addRight = false
    return [
      { accessor: 'id', show: false, },
      { accessor: 'customer_id', show: false, },
      { accessor: 'animalgroup_id', show: false, },
      { accessor: 'type', show: false, },
      this.addTextCell('drive_time', 'starttime', 3),
      this.addTextCell('last_user_name', 'user', 0, 6*this.state.fontSize, supportRight, "", null, null, null, false),
      this.addDateCell('create_time', 'last_update', supportRight, 8.2*this.state.fontSize, true, 'center', null, null, false),
      //this.addDateCell('create_time', 'create_time', true, 8.2, false, 'center', null, null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 7.2*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          if (this.state.row.status < 5 && !this.state.row.used) return this.edit(row, addRight, userRight, userRight)
          else return null
        },
      }
    ]
  }
  createColumnsEvents() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    return [
      { accessor: 'id', show: false, },
      this.addSelectCell("drive_event", "event_id", this.state.events, 8, true, null, null, false, false),
      this.addTextCell('event_time', 'timestamp', 0, 8, true, "", null, null, null, false),
      this.addTextCell('event_value', 'value', 0, 4, true, "", "right", null, null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 4*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          return <img src={imgTrash} alt={t("delete")} title={t("delete")} onClick={() => { this.handleEventDelete(row.id) } } width={em + "px"}/>
        },
      },
    ]
  }

  render() {
    //console.log("Drives", this.state.maxDrives)
    if (this.state.id !== 0) {
      const supportRight = UserProfile.checkRight("support")
      const userRight = UserProfile.checkRight("custuser")
      const columns = this.createColumns()
      const columnsEvents = this.createColumnsEvents()
      const saveData = value => { this.onFormSubmit() }
      const saveEvent = value => { this.storeEvent(value) }
      const changeExtraDrive = value => { if (this.handleInputChars(value, 1)) this.setState({ extradrive: value.target.value, }) }
      const changeDate = value => { this.setState({ date: value.target.value, }) }
      const changeEvent = value => { this.setState({ event: value.target.value, }) }
      const cancelEvent = value => { this.setState({ event: -1, }) }
      let e1 = document.getElementById("edit_window")
      if (e1){
        const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
        e1.style.left = (window.scrollX+15*em)+'px'
        e1.style.top =(window.scrollY+10*em)+'px';
        //e1.style.left = (this.state.position[0]-28*em)+'px';
        //e1.style.top = (this.state.position[1]-2*em)+'px';
      }
      return (
        <Draggable handle=".handle">
        <div className="edittable" id="edit_window">
          <div className="header">
            <div className="header_inline handle">{t('animals_group')}: {this.state.row.name}</div>
            <div className="header_inline close"><img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div>
          </div>
          <form onSubmit={saveData}>
          <table>
          <thead>
              <tr><th colSpan="2">{t('route_drivetimes')}</th></tr>
            </thead>
          </table>
          <div style={{"font-size": this.state.fontSize+"em"}}>
          <div className={supportRight ? "table_80": "table_60"}>
            <ReactTable
              data={this.state.data}
              columns={columns}
              minRows = {0}
              previousText= {'<'}
              nextText= {'>'}
              useFlexLayout = {true}
              showPaginationBottom={false}
              getTrProps={(state, rowInfo, column, instance) => {
                return {
                    onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && this.state.row.status < 5 && this.state.row.used === 0 && userRight) { this.setState({ selected: rowInfo.index, }) } },
                    style: {
                      background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white',
                      color: rowInfo && rowInfo.index === this.state.selected ? 'white' : rowInfo.row.id < 0 ? 'red' : 'black'
                    }
                }
              }}
            />
          </div></div>
          </form>
          {this.state.row.status === 4 && userRight ?
          <div align ="center">
            <br />
            <form onSubmit={saveEvent}>
              <div className="table_buttons">
                <b>{t("animalgroup_add_event")}</b>
              </div>
              <div className="table_buttons">
                <select name="add_event" value={this.state.event} onChange={changeEvent} className="table_input">
                  <option key="-1" value="-1"></option>
                  { Object.entries(this.state.events).map((t,k) => <option key={k} value={t[0]}>{t[1]}</option>) }
                </select>
                { this.state.event === "0" || this.state.event === "3" ? <span>{t("animals_feedamount")}: <input type="text" name="extra_drive1" value={this.state.extradrive} onChange={changeExtraDrive} className="right_align"/>{this.state.event === "0" ? "%" : "kg"}</span> : null} 
                { this.state.event === "1" || this.state.event === "2" ? <span>{t("date")}: <TextField id="event_date" type="date" defaultValue={this.state.date} InputLabelProps={{ shrink: false,}} onChange={changeDate} className="dateInput"/></span> : null}
              </div>
              <div className="table_buttons">
                <button onClick={saveEvent} disabled={this.state.event < 0 ? true : false } className="button">{t("save")}</button> 
                <button onClick={cancelEvent} disabled={this.state.event < 0 ? true : false } className="button">{t("cancel")}</button> 
              </div>
            </form>
          </div>
          : null}
          <ReactTable
            data={this.state.eventList}
            columns={columnsEvents}
            minRows = {0}
            previousText= {'<'}
            nextText= {'>'}
            useFlexLayout = {true}
            showPaginationBottom={false}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                  style: {
                    background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white',
                    color: rowInfo && rowInfo.index === this.state.selected ? 'white' : rowInfo.row.id < 0 ? 'red' : 'black'
                  }
              }
            }}
          />
          <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
        </div>
        </Draggable>
      )
    }
    else return null
  }
}
class MainGroups extends TableFunctions {
  constructor() {
    super()
    this.state = {
      geturl: "/feed/get_combined_groups.php",
      saveurl: "/feed/set_combined_groups.php",
      data: null, empty: {id: -1, customer_id: -1, name: "", group_list: "", user_id: -1, last_update: ""}, selected: null, // Table data
      confirmVisible: false, title: null, message: null, choise: true, // OK popup and Error Messages
      edit: false,
      fontSize: setTableFontSize(),
    }
    this.handleClose = this.handleClose.bind(this)
  }
  reload(newId) {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      if (result.data === null) result.data = [this.state.empty]
      this.setState({
        data: result.data,
        selected: null,
      })
    })
    this.props.reload(newId)
  }
  handleClose() {
    this.props.closeDiv(1);
  }
  static getDerivedStateFromProps(props, state) {
    if (state.selected === null) {
      return {
        index: props.index,
        data: props.mainGroups,
        position: props.position,
      }
    }
    else return null
  }
  componentDidMount() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let e1 = document.getElementById("edit_window")
    e1.style.left = (window.scrollX+15*em)+'px'
    e1.style.top =(window.scrollY+15*em)+'px';
  }
  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
    const supportRight = UserProfile.checkRight("support")
    //let addRight = userRight
    //if (this.state.data.length >= this.state.maxDrives) addRight = false
    return [
      { accessor: 'id', show: false, },
      { accessor: 'customer_id', show: false, },
      //{ accessor: 'animalgroup_id', show: false, },
      this.addTextCell('name', 'name', 0),
      this.addDateCell('create_time', 'last_update', supportRight, 8.2*this.state.fontSize, true, 'center', null, null, false),
      { accessor: 'edit',
        Header: t('table_edit'),
        width: 7.2*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, true, true, true) },
      }
    ]
  }
  render() {
    const columns = this.createColumns()
    //const saveData = value => { this.onFormSubmit() }
    let e1 = document.getElementById("edit_window")
    if (e1){
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
      e1.style.left = (window.scrollX+15*em)+'px'
      e1.style.top =(window.scrollY+15*em)+'px';
    }
    //console.log(this.state.data)
    return (
      <Draggable handle=".handle">
      <div className="edittable" id="edit_window">
        <div className="header">
          <div className="header_inline handle">{t('animals_combined_group')}: </div>
          <div className="header_inline close"><img src={require("./img/close.png")} alt={t('close')} className="close" onClick={this.handleClose} onMouseOver={(e) => {e.currentTarget.src = require("./img/close_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/close.png")}} /></div>
        </div>
        {this.state.data ?
        <div style={{"font-size": this.state.fontSize+"em"}}>
        <div className="table_60">
          <ReactTable
            data={this.state.data}
            columns={columns}
            minRows = {0}
            previousText= {'<'}
            nextText= {'>'}
            useFlexLayout = {true}
            showPaginationBottom={false}
            getTrProps={(state, rowInfo, column, instance) => {
              return {
                onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected) { this.setState({ selected: rowInfo.index, }) } },
                style: {
                  background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : 'white',
                  color: rowInfo && rowInfo.index === this.state.selected ? 'white' : rowInfo.row.id < 0 ? 'red' : 'black'
                }
              }
            }}
          />
        </div></div>
        : null }
        <AlertDialog  openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
      </Draggable>
    )
  }
}

class AnimalGroup extends TableFunctions {
  constructor() {
    super();
    this.state={
      geturl: "/feed/get_animalgroup.php",
      saveurl: "/feed/set_animalgroup.php?robot_id=" + parseInt(window.sessionStorage.getItem("robot_id")),
      updateurl: "/feed/send_animalgroup.php",
      setTableUrl: "/users/change_tablesize.php",
      data: [], orgData: [], empty: null, selected: null, // Table data
      customers: null, box: null, cleardrive: null, cleardrive_empty: null, recipe: null, yesNo: [t('no'), t('yes')], cowTypes: [], mainGroupList: [], // Select input options
      confirmVisible: false, title: null, message: null, choise: true,  // OK popup and Error Messages
      status: getStatusVars(), statusColor: getStatusColors(), fontSize: setTableFontSize(),  // Status and colors
      showHelp: false, showHelpId: null, // Info popup
      showfields: null, fieldWidths: null, manageFields: false, tablePageSize: 0, // Show table fields
      showPosition: [], showHideEdit: false, selectedRow: null, advancedIndex: null, // Advanced insert window
      showHideMainGroups: false, mainGroups: null,
      historic: false, archive: false,
      maxDrives: 10,
      spsFilter: -1,
      robotOnline: 0,
    }
    this.closeEdit = this.closeEdit.bind(this)
    this.reload = this.reload.bind(this)
    this.handleUpdateNewAnimals = this.handleUpdateNewAnimals.bind(this)
  }

  reload(newId) {
    this.componentDidMount(this.state.historic, newId, 0, this.state.archive)
  }
  componentDidMount(historic, newId = null, newEntry = 0, archive = false) {
    //console.log("load mount")
    let addLink = "?new_entry=" + newEntry
    if (historic) addLink = addLink+"&historic=1"
    if (archive) addLink = addLink+"&archive=1"
    GetJsonFormPHP(this.state.geturl+addLink).then((result) => {
      this.setState({
        empty: result.empty,
        emptydrives: result.empty_drives,
      })
      if (result.data === null) result.data = [this.state.empty]
      if (newId != null) {
        result.data.map((t,k) => { if (parseInt(newId) === parseInt(t.id)) this.setState({ selectedRow: t, advancedIndex: k,}); return null})
      }
      let pageSize = 25
      if (result.fieldwidth && result.fieldwidth._pageSize) pageSize = result.fieldwidth._pageSize
      //if (!result.maingroups) result.maingroups = [{id: -1, customer_id: -1, name: "", group_list: "", user_id: 4, last_update: "2024-01-01 00:00:00"}]
      this.setState({
        data: result.data,
        orgData: result.data,
        customers: result.customers,
        box: result.box,
        cleardrive: result.cleardrive,
        cleardrive_empty: result.cleardrive_empty,
        recipe: result.recipe,
        cowTypes: result.cowtypes,
        showfields: result.showfields,
        fieldWidths: result.fieldwidth,
        maxDrives: result.max_drives,
        groupsSelect: result.groupsSelect,
        robotOnline: result.robot_online,
        tablePageSize: pageSize,
        mainGroups: result.maingroups,
        mainGroupList: result.maingrouplist,
      })
      if (newEntry === 1) this.insertRow()
    })
    //this.setState({fontSize: setTableFontSize(),})
  }
  radioboxCell(initialValue, id, row, options, field) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    if (this.state.selected === row._index) {
      return (
        <div align="left">
          <label><input type="radio" name={id} value="1" className="checkbox" checked={field === 1 || field === "1"} onChange={inputChange} /><span className="radiotext">{options[1]}</span></label><br />
          <label><input type="radio" name={id} value="0" className="checkbox" checked={field === 0 || field === "0"} onChange={inputChange} /><span className="radiotext">{options[0]}</span></label>
        </div>
      )
    }
    else {
      if (initialValue === "1") return (<div width="100%" align="center"><img src={imgTrue} alt={t("enabled")} title={t("enabled")} /></div>)
      //else return (<div width="100%" align="center"><img src={imgFalse} alt={t("disabled")} title={t("disabled")} /></div>)
      else return (<div width="100%" align="center">-</div>)
    }
  }
  closeEdit(closediv = 0) {
    if (closediv === 1){
      this.setState({
        selected: null,
        showHideMainGroups: false,
      })
    }
    else {
      this.setState({
        selected: null,
        showHideEdit: false,
      })
    }
    this.componentDidMount(this.state.historic, null, 0, this.state.archive)
  }
  radiomilkCell(initialValue, id, row, options) {
    const inputChange = value => { this.handleInputChange(row._index, id, value) }
    if (this.state.selected === row._index) {
      return (
        <div>
          <label className="checkboxlabel"><input type="radio" name={id} value="1" className="checkbox" checked={parseInt(row.milk) === 1} onChange={inputChange} />{options[0]}</label><br />
          <label className="checkboxlabel"><input type="radio" name={id} value="2" className="checkbox" checked={parseInt(row.milk) === 2} onChange={inputChange} />{options[4]}</label><br />
          <label className="checkboxlabel"><input type="radio" name={id} value="3" className="checkbox" checked={parseInt(row.milk) === 3} onChange={inputChange} />{options[2]}</label><br />
          <label className="checkboxlabel"><input type="radio" name={id} value="4" className="checkbox" checked={parseInt(row.milk) === 4} onChange={inputChange} />{options[3]}</label><br />
          <label className="checkboxlabel"><input type="radio" name={id} value="10" className="checkbox" checked={parseInt(row.milk) === 10} onChange={inputChange} />{options[1]}</label>
        </div>
      )
    }
    else {
      let v = parseInt(initialValue)
      if (v === 1) return (<div width="100%" align="center"><img src={imgMilk} alt={t("animals_milkcows")} title={t("animals_milkcows")} /></div>)
      else {
        if (v === 2) return (<div width="100%" align="center"><img src={imgDrycows} alt={t("animals_drycows")} title={t("animals_drycows")} /></div>)
        else {
          if (v === 3) return (<div width="100%" align="center"><img src={imgBull} alt={t("animals_bulls")} title={t("animals_bulls")} class="bull" /></div>)
          else {
            if (v === 4) return (<div width="100%" align="center"><img src={imgCalve} alt={t("animals_calves")} title={t("animals_calves")} /></div>)
            else {
              if (v === 10) return null //return (<div width="100%" align="center"><img src={imgCalve} alt={t("animals_calves")} title={t("animals_calves")} /></div>)
              else return null
            }
          }
        }
      }
    }
  }
  handleUpdateNewAnimals = (row) =>{
    //console.log("upadte animals")
    GetJsonFormPHP(this.state.saveurl +"&updateAnimalsID=" + row.id).then((result) => {
      this.reload()
    })
  }
  setfilter(value) {
    let spsFilter = this.state.spsFilter
    if (value.name === "filter_sps_nr") spsFilter = value.value
    let  a = []
    //console.log(spsFilter, this.state.orgData)
    if (spsFilter > 0) {
      // eslint-disable-next-line array-callback-return
      this.state.orgData.map((t,k) => { if (t.sps_nr === parseInt(spsFilter) || t.id === -1)  a = [...a, t] })
    }
    else a = this.state.orgData
    this.setState({
      data: a,
      spsFilter: spsFilter,
    })
  }
  handleCopy(row) {
    //console.log("saveurl:", this.state.saveurl + "&copyID=" + row.id)
    GetJsonFormPHP(this.state.saveurl + "&copyID=" + row.id).then((result) => {
      if (result.error) {
        this.setState({
          confirmVisible: true,
          title: t("error"),
          message: t(result.error),
          choise: false,
          selected: null,
        })
      }
      this.reload()
    })
  }

  addSelectCellCowTypes(header, accessor, options, cellwidth = 0, show = true, align = null, cellColor = null, addempty = false, edit = true, filterable = false, value = false, mouseoverOptions = null, ratio = false, editOnNew = false) {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    let result = {
      Header: t(header),
      accessor: accessor,
      show: show,
      Cell: ({value: initialValue, column: { id }, row}) => {
        if (this.state.selected !== row._index) {
          let v = parseInt(initialValue)
          if (v === 1) return (<div width="100%" align="center"><img src={imgMilk} alt={t("animals_milkcows")} title={t("animals_milkcows")} /></div>)
          if (v === 2) return (<div width="100%" align="center"><img src={imgDrycows} alt={t("animals_drycows")} title={t("animals_drycows")} /></div>)
          if (v === 3) return (<div width="100%" align="center"><img src={imgBull} alt={t("animals_bulls")} title={t("animals_bulls")} class="bull" /></div>)
          if (v === 4) return (<div width="100%" align="center"><img src={imgCalve} alt={t("animals_calves")} title={t("animals_calves")} /></div>)
          if (v === 10) return null //return (<div width="100%" align="center"><img src={imgCalve} alt={t("animals_calves")} title={t("animals_calves")} /></div>)
          return null
        }
        else return this.selectCell(id , row, initialValue, options, addempty, edit || (editOnNew && row.id <= 0), value ? initialValue : null, mouseoverOptions, ratio)
      },
      getProps: (state, rowInfo) => ({ style: { textAlign: align, backgroundColor: rowInfo.row.status === 5 || rowInfo.row.status === 6 ? this.getStatusBackColor(rowInfo.row.status) : cellColor === 'readonly' ? this.getStatusBackColor(rowInfo.row.status, true) : cellColor } }),
      filterable: filterable,
    }
    if (cellwidth > 0) result.width = cellwidth*em
    return result
  }

  createColumns() {
    const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    const supportRight = UserProfile.checkRight("support")
    const userRight = UserProfile.checkRight("custuser")
    //console.log(window.screen.availWidth, em, this.state.fontSize, getComputedStyle(document.body).getPropertyValue("font-size"))
    //if (supportRight) fontsize = 0.8
    const w = this.state.fieldWidths
    const fontSize = this.state.fontSize
    //console.log(w)
    return [
      { accessor: 'id', show: false,},
      { accessor: 'customer_id', show: false,},
      { accessor: 'org_animalgroup_id', show: false,},
      { accessor: 'robot_id', show: false,},
      { accessor: 'used', show: false,},
      { accessor: 'box_error', show: false,},
      { accessor: 'cowhistory', show: false,},
      { accessor: 'update_a', show: false,},
      { accessor: 'animals_new', show: false,},
      { accessor: 'sps_nr',
        Header: t('animals_groupnr'),
        getProps: (state, rowInfo) => ({ style: { textAlign: "right" } }),
        Cell: ({value: initialValue, column: { id }, row,}) => { if (supportRight) return this.textCell(initialValue, id, row, 1); else return parseInt(initialValue) < 0 ? null : initialValue },
        width: (w && w['sps_nr'] ? w['sps_nr'] : 2.4)*fontSize*em,
      },
      this.addTextCell('name', 'name', 0, w && w['name'] ? w['name']*fontSize : 0),
      this.addNumberCell('animals_animals', 'animals', 1, 0, 500, (w && w['animals'] ? w['animals'] : 3.2)*fontSize, this.state.showfields ? this.state.showfields['animals'] : true),
      { accessor: 'new_animals',
        show: this.state.showfields ? this.state.showfields['animals_new'] : true,
        Header: t('animals_animals_new'),
        getProps: (state, rowInfo) => ({ style: { textAlign: "right", backgroundColor: rowInfo.row.status >= 5 ? this.getStatusBackColor(rowInfo.row.status) : this.getStatusBackColor(rowInfo.row.status, true) } }),
        Cell: ({value: initialValue, column: { id }, row,}) => {
          if (row.new_animals) return (<span title={row.cowhistory}><span dangerouslySetInnerHTML={{__html: initialValue}}></span></span>)
          else {
            if (row.cowhistory) return (<span title={row.cowhistory}>{row.animals_new}</span>)
            else return null
          }
        },
        width: (w && w['animals_new'] ? w['animals_new'] : 5.2)*fontSize*em,
      },
      this.addTextCell('box_path', 'path', 0, (w && w['path'] ? w['path'] : 5.5)*fontSize, this.state.showfields ? this.state.showfields['path'] : true, "", "center", 'readonly', null, false),
      this.addTextCell('animals_feedamount', 'feedamount', 1, (w && w['feedamount'] ? w['feedamount'] : 5.2)*fontSize, this.state.showfields ? this.state.showfields['feedamount'] : true, "%"),
      this.addSelectCell('animals_org_recipe_id', 'org_recipe_id', this.state.recipe, (w && w['org_recipe_id'] ? w['org_recipe_id'] : 6)*fontSize, this.state.showfields ? this.state.showfields['org_recipe_id'] : true, 'center'),
      this.addSelectCell('animals_box_id', 'box_id', this.state.box, (w && w['box_id'] ? w['box_id'] : 7)*fontSize, this.state.showfields ? this.state.showfields['box_id'] : false, 'center'),
      this.addTextCell('animals_box_runs', 'box_runs', 1, (w && w['box_runs'] ? w['box_runs'] : 4)*fontSize, this.state.showfields ? this.state.showfields['box_runs'] : true),
      this.addTextCell('animals_mixtime', 'mixtime', 1, (w && w['mixtime'] ? w['mixtime'] : 5.5)*fontSize, this.state.showfields ? this.state.showfields['mixtime'] : true, "sec"),
      this.addTextCell('animals_mixspeed', 'mixspeed', 1, (w && w['mixspeed'] ? w['mixspeed'] : 5)*fontSize, this.state.showfields ? this.state.showfields['mixspeed'] : true, "%"),
      this.addTextCell('animals_mix_rest_amount', 'mix_rest_amount', 2, (w && w['mix_rest_amount'] ? w['mix_rest_amount'] : 5)*fontSize, this.state.showfields ? this.state.showfields['mix_rest_amount'] : true, "kg", null),
      this.addTextCell('animals_mix_rest_slowamount', 'mix_rest_slowamount', 2, (w && w['mix_rest_slowamount'] ? w['mix_rest_slowamount'] : 5)*fontSize, this.state.showfields ? this.state.showfields['mix_rest_slowamount'] : true, "kg", null),
      this.addTextCell('animals_mix_rest_speed', 'mix_rest_speed', 2, (w && w['mix_rest_speed'] ? w['mix_rest_speed'] : 5)*fontSize, this.state.showfields ? this.state.showfields['mix_rest_speed'] : true, "%", null),
      this.addSelectCell('animals_fix_start_end', 'fix_start_end', this.state.yesNo, (w && w['fix_start_end'] ? w['fix_start_end'] : 4)*fontSize, this.state.showfields ? this.state.showfields['fix_start_end'] : false, "center", null, false,    true, false, false, null, true),
      this.addNumberCell('animals_ejection', 'ejection', 1, 0, 100, (w && w['ejection'] ? w['ejection'] : 5)*fontSize, this.state.showfields ? this.state.showfields['ejection'] : true, "%", null, null, null, true),
      this.addTextCell('animals_off_delay', 'off_delay', 2, (w && w['off_delay'] ? w['off_delay'] : 5)*fontSize, this.state.showfields ? this.state.showfields['off_delay'] : true, '', null, null, null, true),
      this.addTextCell('animals_driving', 'driving', 1, (w && w['driving'] ? w['driving'] : 5)*fontSize, this.state.showfields ? this.state.showfields['driving'] : true, "%", null, null, null, true),
      this.addTextCell('animals_curve', 'curve', 1, (w && w['curve'] ? w['curve'] : 4)*fontSize, this.state.showfields ? this.state.showfields['curve'] : true),
      this.addSelectCell('animals_flap_not_closed', 'flap_not_closed', this.state.yesNo, (w && w['flap_not_closed'] ? w['flap_not_closed'] : 4)*fontSize, this.state.showfields ? this.state.showfields['flap_not_closed'] : true, "center", null, false, true, false, false, null, true),
      this.addSelectCell('animals_org_cleardrives_id', 'org_cleardrives_id', this.state.cleardrive, (w && w['org_cleardrives_id'] ? w['org_cleardrives_id'] : 8)*fontSize, this.state.showfields ? this.state.showfields['org_cleardrives_id'] : false, 'right'),
      this.addSelectCellCowTypes('animals_milk', 'milk', this.state.cowTypes, (w && w['milk'] ? w['milk'] : 8)*fontSize, this.state.showfields ? this.state.showfields['milk'] : false, 'right'),
      this.addSelectCell("animals_combined_group", "combined_group_id", this.state.mainGroupList, (w && w['combined_group_id'] ? w['combined_group_id'] : 6)*fontSize, this.state.showfields ? this.state.showfields['combined_group_id'] : false, 'center', null, true),
      this.addTextCell('animals_animal_weight_gain', 'animal_weight_gain', 2, (w && w['animal_weight_gain'] ? w['animal_weight_gain'] : 5.2)*fontSize, this.state.showfields ? this.state.showfields['animal_weight_gain'] : true, ""),
      this.addDateCell('animals_setting_date', 'setting_date', false, (w && w['setting_date'] ? w['setting_date'] : 5.6)*fontSize,  this.state.showfields ? this.state.showfields['setting_date'] : true, "center", null, null, true, false, true),
      this.addTextCell('animals_setting_weight', 'setting_weight', 2, (w && w['setting_weight'] ? w['setting_weight'] : 5.2)*fontSize, this.state.showfields ? this.state.showfields['setting_weight'] : true, ""),
      this.addDateCell('animals_slaughter_date', 'slaughter_date', false, (w && w['slaughter_date'] ? w['slaughter_date'] : 5.6)*fontSize,  this.state.showfields ? this.state.showfields['slaughter_date'] : true, "center", null, null, true, false, true),
      this.addTextCell('animals_slaughter_weight', 'slaughter_weight', 2, (w && w['slaughter_weight'] ? w['slaughter_weight'] : 5.2)*fontSize, this.state.showfields ? this.state.showfields['slaughter_weight'] : true, ""),
      this.addTextCell('animals_animal_weight', 'animal_weight', 0, (w && w['animal_weight'] ? w['animal_weight'] : 5.2)*fontSize, this.state.showfields ? this.state.showfields['animal_weight'] : true, "", null, "readonly", null, false),
      /*{ accessor: 'milk',
        Header: t('animals_milk'),
        maxWidth: (w && w['milk'] ? w['milk'] : 6.8)*fontSize*em,
        show: this.state.showfields ? this.state.showfields['milk'] : true,
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.radiomilkCell(initialValue, id , row, [t('animals_milkcows'), "    -", t('animals_bulls'), t('animals_calves'), t('animals_drycows')]) },
      },*/
      this.addTextCell('animals_vol', 'vol', 2, (w && w['robot_fill'] ? w['robot_fill'] : 5.5)*fontSize, this.state.showfields ? this.state.showfields['vol'] : true, "m³", null, 'readonly', null, false, false, 1),
      { accessor: 'drives',
        Header: t('animals_drives_check'),
        Cell: ({value: initialValue, column: { id }, row,}) => {
          //console.log(row.name, row.drives, row.animals, row.vol, window.sessionStorage.getItem("robot_load"))
          //console.log(row.drives !== null, row.animals > 0, row.vol < window.sessionStorage.getItem("robot_load"))
          if (row) {
            let img = imgOk
            //if (row.drives && row.drives !== null && row.animals > 0 && row.vol < window.sessionStorage.getItem("robot_load") && row.box_id > 0 && row.org_recipe_id > 0) {
            //}
            //else {
            //console.log(row.drives, row.animals, window.sessionStorage.getItem("robot_load"))
            let title = ""
            if (row.drives === null) title= title + t("animals_drives_missing")
            if (row.animals < 1) {
              if (title !== "") title = title + "\n"
              title = title + t("animals_no_animal")
              img = imgAttention
            }
            if (row.feedamount <= 0) {
              if (title !== "") title = title + "\n"
              title = title + t("animals_amount_missing")
              img = imgAttention
            }
            if (!row.drives) {
              if (title !== "") title = title + "\n"
              title = title + t("animals_drives_missing")
              img = imgAttention
            }
            if (row.box_id <= 0) {
              if (title !== "") title = title + "\n"
              title = title + t("animals_box_missing")
              img = imgAttention
            }
            if (row.org_recipe_id <= 0) {
              if (title !== "") title = title + "\n"
              title = title + t("animals_recipe_missing")
              img = imgAttention
            }
            if (row.box_runs <= 0) {
              if (title !== "") title = title + "\n"
              title = title + t("animals_boxdrives_missing")
              img = imgAttention
            }
            if (row.box_error > 0) {
              if (title !== "") title = title + "\n"
              title = title + t("animals_box_duplicate")
              img = imgAttention
            }
            if (row.vol >= window.sessionStorage.getItem("robot_load")) {
              const vol = window.sessionStorage.getItem("robot_load")/0.9
              if (title !== "") title = title + "\n"
              if (row.vol >= window.sessionStorage.getItem("robot_load")/0.9) {
                title = title + t("animals_too_high_vol") + " (>" + vol + "m³)"
                img = imgAttention
              }
              else {
                title = title + t("animals_high_vol") + " (~" + window.sessionStorage.getItem("robot_load") + "m³)"
                if (img !== imgAttention) img = imgWarning
              }
            }
            if (img === imgOk) {
              if (row.status === 3) return (<div width="100%" align="center"><img src={imgArrow} alt={t("-->")} title={t("transfer_to_robot")} /></div>)
              else if (row.status === 4) return (<div width="100%" align="center"><img src={imgTrue} alt={t("status_active")} title={t("status_active")} /></div>)
              else if (row.status > 4) return (<div width="100%" align="center"><img src={imgFalse} alt={t("disabled")} title={t("disabled")} /></div>)
              else return (<div width="100%" align="center"><img src={imgOk} alt={t("ok")} title={t("ok")} /></div>)
            }
            else return (<div width="100%" align="center"><img src={img} alt={title} title={title} />{row.status === 3 ? <span><img src={imgArrow} alt={t("-->")} title={t("transfer_to_robot")} /></span>: null}</div>)
          }
          else return null
        },
        maxWidth: (w && w['check'] ? w['check'] : 4.5)*fontSize*em,
        getProps: (state, rowInfo) => ({ style: { textAlign: "center", backgroundColor: this.getStatusBackColor(rowInfo.row.status, true) } }),
      },
      this.addSelectCell("status", "status", this.state.status, (w && w['status'] ? w['status'] : 5)*fontSize, supportRight, 'center', this.getColor('readOnly')),
      this.addDateCell('create_time', 'create_time', false, (w && w['create_time'] ? w['create_time'] : 5.6)*fontSize,  this.state.showfields ? this.state.showfields['create_time'] : true, "center", "readonly", null, false),
      this.addTextCell('last_user_name', 'user', 0, 6*fontSize, supportRight, "", null, this.getColor('readOnly'), "#999999", false),
      { accessor: 'edit',
        Header: t('table_edit'),
        //width: 10.5*em,
        filterable: false,
        resizable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => {
          const getDetails = value => {
            this.setState({
              selectedRow: this.state.data[row._index],
              advancedIndex: row._index,
              showHideEdit: true,
              showPosition: [value.clientX, value.clientY],
            })
            //console.log(this.state.showPosition, value.clientX, value.clientY)
          }
          let del = false, edit = false, update = false, archiv = false
          if (row.status < 2) del = true
          if (row.id > 0 && row.status <= 4) edit = true
          if ((row.status === 0 || row.status === 1 || row.status === 2) &&  this.state.selected !== row.id) update = true
          //if (row.animals <= 0 || row.vol > window.sessionStorage.getItem("robot_load")/0.9 || row.box_id <= 0 || row.org_recipe_id <= 0) update = false
          if (row.status === 4 && row.animals <= 0) archiv = true
          if (supportRight) edit = true
          if (row.used) edit = false
          //{row.status === 6 ? <span><img src={imgCopy} alt={t("copy")} title={t("copy")} onClick={() => { this.handleCopy(row) }}/></span> : null}
          //{del && userRight ? <span><img src={imgTrash} alt={t("delete")} title={t("delete")} onClick={() => { this.setState({selected: row._index, selectedRow: this.state.data[row._index], confirmVisible: true, title: t("delete_line"), choise: true, }) } }/></span> : null }
          //{del && !this.state.selected ? <span><img src={imgTrash} alt={t("delete")} title={t("delete")} onClick={() => { this.setState({selected: row._index, selectedRow: this.state.data[row._index], confirmVisible: true, title: t("delete_line"), choise: true, }) } }/></span> : null }
          // , archiv && userRight
          return (
            <div>
              <img src={imgDetail} alt={t("animal_detail")} title={t("animal_detail")} onClick={getDetails}/>
              {this.edit(row, false, del && userRight, edit && userRight, update && userRight, archiv && userRight)}
              {row.new_animals && this.state.selected !== row._index && row.update_a === 1 ? <span><img src={imgInstall} alt={t("send_animals_to_robot")} title={t("send_animals_to_robot")} onClick={() => { this.handleUpdateNewAnimals(row) }}/></span> : null }
              {(row.status < 4 ||  row.update_a === 1) && this.state.robotOnline === 0 ? <img src={imgWarnOnline} alt="!" title={t("robot_offline")} /> : null}
            </div>
          )
        },
      }
    ]
  }

  render() {
    const userRight = UserProfile.checkRight("custuser")
    const supportRight = UserProfile.checkSupport()
    const columns = this.createColumns()
    const closeEditCols = value => {this.setState({ manageFields: false}); this.componentDidMount()}
    const selectChange  = value => { this.setfilter(value) }
    const changeColumnsWidth = (width, name) => {
      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))*this.state.fontSize
      GetJsonFormPHP(this.state.setTableUrl + "?table=animalgroup&col=" + name + "&width=" + width + "&em=" + em).then((result) => {
      })
    }
    let buttonHistoric = t('show_history')
    if (this.state.historic) buttonHistoric = t('hide_history')
    let buttonArchive = t('show_archive')
    const colourStyles = {
      control: (styles) => ({ ...styles, height: 28, minHeight: 28, minWidth: 280, }),
      menuList: (provided, state) => ({ ...provided, paddingTop: 0, }),
      menu: base => ({ ...base, marginTop: 0, }),
      container: (base) => ({ ...base, display:'inline-block', }),
      indicatorContainer: (styles) => ({ ...styles, padding: 0, margin: 0, }),
      option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        const color = data.color
        return {
          ...styles,
          backgroundColor: isDisabled ? undefined
            : isFocused ? color //  color.alpha(0.1).css()
            : isSelected ? color //data.color
            : undefined,
          color: isDisabled ? '#ccc'
            : isFocused  ? 'white'
            : isSelected ? 'white'
            : data.color,
          cursor: isDisabled ? 'not-allowed' : 'default',
          ':active': {
            ...styles[':active'],
            backgroundColor: !isDisabled ? isSelected ? data.color : color  //color.alpha(0.3).css()
              : undefined,
          },
          paddingTop: 2,
          paddingBottom: 2,
        };
      },
    }
    //console.log(window.sessionStorage)
    //console.log(this.state.tablePageSize)
    //<button onClick={() => {this.setState({ historic: false, archive: !this.state.archive, }); this.componentDidMount(false, null, 0, !this.state.archive)}}>{buttonArchive}</button>
    //{userRight ? <button onClick={() => {columns[11].show = true; this.componentDidMount(this.state.historic, null, 1)}}>{t("addline")}</button> : null }
    return (
      <div>
        <div className="table_buttons">
          {supportRight ? <button className="only_support" onClick={() => {columns[11].show = true; this.componentDidMount(this.state.historic, null, 1)}}>{t("addline")}</button> : null }
          {this.state.historic ? <span>
            {t('filter_sps_nr')}:&nbsp;
            <Select
              options={this.state.groupsSelect}
              styles={colourStyles}
              isSearchable={true}
              isClearable={true}
              className="chartselect"
              onChange={(value) => {selectChange({name: "filter_sps_nr", value: value !== null ? value.value : 0})}}
              placeholder={t('select')}
            />
          </span> : null }
          <button className={this.state.historic ? "button_active" : null} onClick={() => {this.setState({ historic: !this.state.historic, archive: false, }); this.componentDidMount(!this.state.historic, null, 0, false)}}>{buttonHistoric}</button>
          {supportRight ? <button className={this.state.archive ? "button_active" : null + " only_support"} onClick={() => {this.setState({ historic: false, archive: !this.state.archive, }); this.componentDidMount(false, null, 0, !this.state.archive)}}>{buttonArchive}</button> : null }
          <button onClick={() => {this.setState({ manageFields: true, })}}>{t("show_advanced")}</button>
          <button onClick={() => {this.setState({ showHideMainGroups: !this.state.showHideMainGroups,}); }}>{t("animals_combined_group")}</button>
          <div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" onClick={() => { this.setState({ showHelp: true, showHelpId: "help_animals_table", }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div>
        </div>
        {this.state.tablePageSize > 0 && this.state.data ?
        <div style={{fontSize: this.state.fontSize+"em"}}>
        <ReactTable
          id="react-table"
          data={this.state.data}
          columns={columns}
          minRows = {0}
          previousText= {'<'}
          nextText= {'>'}
          showPageJump= {true}
          defaultPageSize = {this.state.tablePageSize}
          pageSizeOptions = {[10, 25, 50, 100]}
          useFlexLayout = {true}
          //responsive = {true}
          //expanded={this.state.expanded}
          getTrProps={(state, rowInfo, column, instance) => {
            return {
                onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected && rowInfo.row.status < 5 && !rowInfo.row.used && userRight) { this.setState({ selected: rowInfo.index, }) } },
                style: {
                  background: rowInfo && rowInfo.index === this.state.selected ? '#AFC9DF' : this.getStatusBackColor(rowInfo.row.status),
                  color: rowInfo && rowInfo.index === this.state.selected ? this.getColor('selected') : rowInfo.row.id < 0 || rowInfo.row.feedamount <= 0 || rowInfo.row.animals <= 0 || rowInfo.row.box_error > 0 || rowInfo.row.box_id <= 0 || rowInfo.row.box_runs <= 0 || !rowInfo.row.drives ? this.getStatusColor(10) : this.getStatusColor(rowInfo.row.status),
                  fontSize: rowInfo.row.status > 4 ? "0.9em" : null
                }
            }
          }}
          getTheadTrProps={(state, column, instance) => {
            return {
              onClick:  (e) => { if (!UserProfile.isSafari()) changeColumnsWidth(e.target.offsetParent.style.width, e.target.offsetParent.innerText) },
            }
          }}
          getProps = {(state, column, instance) => {
            return {
              onChange:  (e) => { if (e.target.type !== "number" && e.target.type !== "text" && e.target.name === "") changeColumnsWidth(e.target.value, "_pageSize") },
            }
          }}
        />
        </div>
        : null}
        {this.state.showHideEdit && <Edit row = {this.state.selectedRow} index = {this.state.advancedIndex} maxDrives = {this.state.maxDrives} emptydrives = {this.state.emptydrives} position = {this.state.showPosition} closeDiv={this.closeEdit} reload={this.reload} />}
        {this.state.showHideMainGroups && <MainGroups mainGroups = {this.state.mainGroups} position = {this.state.showPosition} closeDiv={this.closeEdit} reload={this.reload} />}
        {this.state.showHelp && <Info id={this.state.showHelpId} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        {this.state.manageFields && <ManageCols showfields={this.state.showfields} closeDiv={closeEditCols} page={"animals"}/>}
        <AlertDialog openDialog = {this.state.confirmVisible} title = {this.state.title} message = {this.state.message} choise = {this.state.choise} callback = {this.handleDelete}/>
      </div>
    )
  }
}
export default AnimalGroup;
