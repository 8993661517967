//import React, { Component } from 'react'
import TableFunctions from '../datatables/api/TableFunctions.js'
import ReactTable from "react-table-6"
import '../datatables/styles/react-table.css'
//import { Link } from 'react-router-dom'
import Info from "../datatables/api/Info";
import { Link } from 'react-router-dom'
//import { Link, useLocation } from 'react-router-dom'
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, PointElement, LineElement, } from 'chart.js'
import { Line } from 'react-chartjs-2'
//import annotationPlugin from 'chartjs-plugin-annotation'
import Translation from '../user/Translation'
import GetJsonFormPHP from "../datatables/api/GetJsonFormPHP"
import UserProfile from '../user/UserProfile'

ChartJS.register(CategoryScale, LinearScale, BarElement, PointElement, LineElement, Title, Tooltip, Legend, )
function t(field) { return Translation.get(field) }

class HomeStat extends TableFunctions {
  constructor() {
    super()
    this.state={
      geturl: "/robots/get_robots.php?main=1",
      getParamurl: "/users/get_customer_param.php",
      data: [],
      robottypes: [],
      params: [],
      label1: [], label2: [], label3: [], label4: [],
      data1: [], data2: [], data3: [], data4: [], errors: [],
      windowWidth: 0, windowHeight: 0,
      showHelp: false, showHelpId: "help_homestat_milk", showPosition: [],  // Info popup
      counter: 0,
    }
    this.handleResize = this.handleResize.bind(this);
  }
  componentDidMount() {
    GetJsonFormPHP(this.state.geturl).then((result) => {
      this.setState({
        data: result.data,
        robottypes: result.robottypes,
      })
    })
    this.fetchParams()
    window.addEventListener('resize', this.handleResize);
    this.handleResize()
  }
  fetchParams() {
    //console.log(window.sessionStorage.getItem("page_id"))
    //console.log(window.localStorage)
    if (window.sessionStorage.getItem("page_id") === "/") {
      GetJsonFormPHP(this.state.getParamurl + "?counter=" + this.state.counter).then((result) => {
        //console.log(result)
        this.setState({
          status: result.status.data,
          params: result.data,
          label1: result.label1,
          data1: result.data1,
          label2: result.label2,
          data2: result.data2,
          label3: result.label3,
          data3: result.data3,
          label4: result.label4,
          data4: result.data4,
          errors: result.errors,
          counter: this.state.counter+1
        })
        //console.log(result.counter , this.state.counter)
      if (result.counter+2 > this.state.counter)  setTimeout(() => { this.fetchParams() }, 60000);
      })
    }
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }
  getOptionsChart() {
    return {
      plugins: {
        title: {
          display: false,
          text: t("chart_feeds_per_day"),
          font: { size: 24, },
        },
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            //font: window.innerWidth > 350 ? 50 : 5
            font: { size: 12, }, // This more specific font property overrides the global property
            boxWidth: 25,
            boxHeight: 5,
          }
        },
        tooltip: {
          callbacks: {
            label: function(context) {
                let label = context.dataset.label || '';
                if (label) { label += ': '; }
                if (context.parsed.y !== null) {
                  //console.log(context.dataset)
                  if (context.parsed.y>0) label += context.parsed.y + " " + context.dataset.unit;
                  else {
                    var d = context.dataset
                    var index = context.dataIndex
                    if (d.event && d.event[index] !== "") {  label = d.event[index].split('|') }
                  }
                }
                return label
            },
            afterTitle: function(data) {
              var d = data[0].dataset
              var index = data[0].dataIndex
              if (d.event && d.event[index] !== "" && d.data[index] >= 0) {
                var events = d.event[index].split('|')
                return events
              }
              return null
            },
          },
          titleAlign: 'left',
          titleColor: 'lightgreen',
          titleMarginBottom: 6,
          titleSpacing: 2,
          backgroundColor:	'rgba(10, 10, 10, 0.8)',
        },
        annotation: {
          annotations: {
            line1: {
              type: 'line',
              yMin: 1.5,
              yMax: 10,
              borderColor: 'rgb(255, 99, 132)',
              borderWidth: 2,
            }
          }
        }

      },
      elements: {
        line: { tension: 0.4 }
      },
      responsive: true,
      categoryPercentage: 0.95,
      barPercentage: 0.9,
      scales: {
        x: {
          //stacked: true,
        },
        y: {
          min: 0,
          ticks: {
            callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' kg'  // '●' +
            else return ""
          } }
        },
        y1_1: {
          display: false,
          min: 0,
          ticks: {
            color: 'rgb(20, 49, 255)',
            callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' kg'  // '●' +
            else return ""
          } }
        },
        y1: {
          display: false,
          min: 0,
          position: 'right',
          ticks: {
            color: 'rgb(20, 219, 155)',
            callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' €'  // '●' +
            else return ""
          } }
        },
        y2: {
          display: false,
          min: 0,
          ticks: {
            callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' kWh'  // '●' +
            else return ""
          } }
        },
        yEff: {
          display: false,
          min: 0,
          ticks: {
            callback: function(value, index, ticks) {
            if (value >= 0 ) return value + ' '  // '●' +
            else return ""
          } }
        },
      },
      //lineAt: 1.5,
      annotation: {
        annotations: [
          {
            type: "line",
            mode: "vertical",
            scaleID: "x-axis-0",
            borderColor: "red",
            value: 1,
            label: {
              content: "Hallo",
              enabled: true,
              position: "top"
            }
          }
        ]
      }
    }
  }
  createColumns() {
    //const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
    const columns = [
      { accessor: 'id', show: false,},
      { accessor: 'service_level', show: false,},
      { accessor: 'color', show: false,},
      this.addTextCell('name', 'name', 0, 10, true, "", null, null, null, false),
      this.addTextCell('status', 'status', 0, 10, true, "", "center", null, null, false),
      this.addTextCell('temperature', 'temperature', 2, 4, true, "°C", "center", null, null, false),
      this.addTextCell('home_status_error', 'error_text', 1, 0, true, "", null, null, "#D00000", false),
      this.addNumberCell('robot_operation_hours', 'hours', 1, 0, 0, 4, true, "", null, null, null, false),
      this.addNumberCell('robot_total_distance', 'distance', 1, 0, 0, 4.8, true, "km", null, null, null, false),
      this.addTextCell('robotfeed_last_update', 'last_update', 0, 5.8, true, "", "right", null, null, false),
      //this.addSelectCell("robottype_name", "robottype_id", this.state.robottypes, 8,),
      //this.addTextCell('serviceinterval_h', 'interval_hours', 1, 6),
      //this.addTextCell('servicestart_time', 'start_days', 1, 6),
      //this.addTextCell('serviceinterval_time', 'interval_days', 1, 6),
      //this.addTextAreaCell('service_info', 'infotext', 30, 6),
      /*{ accessor: 'edit',
        Header: t('table_edit'),
        width: 6*em,
        filterable: false,
        Cell: ({value: initialValue, column: { id }, row,}) => { return this.edit(row, false) },
      }*/
    ]
    return columns
  }

  handleResize() {
    this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
  }

  render() {
    function over(e) {
      switch(e.target.id) {
        case "nav_milk1":
          e.currentTarget.src =  require("../img/euter_mo.png")
          break
        default:
      }
    }
    function out(e) {
      switch(e.target.id) {
        case "nav_milk1":
          e.currentTarget.src =  require("../img/euter.png")
          break
        default:
      }
    }
    //console.log(UserProfile.getUserLevel())
    if ((UserProfile.checkRight("support") && UserProfile.getUserLevel() <= 2)|| UserProfile.checkRight("trader")) {
      return (
        <div></div>
      )
    }
    else {
      let FM, TM, FM_av, TM_av, FM_count, FMToday, TMToday, EnergyFeeder, EnergyRobot, EnergyFeederAv, EnergyRobotAv = ""
      let Milk, Milk_av, Milk_eff, Milk_eff_av, Milk_FEQ, IOFC, IOFC_av, Milk_FEQ_day = "" // Milk_count,
      if (this.state.params) {
        if (this.state.params.FM) {
          FM = this.state.params.FM.sum
          FMToday = this.state.params.FM.today
          FM_av = this.state.params.FM_av.sum
        }
        if (this.state.params.TM) {
          //console.log(this.state.params)
          TM = this.state.params.TM.sum
          TMToday = this.state.params.TM.today
          if (this.state.params.TM_av) TM_av = this.state.params.TM_av.sum
          if (this.state.params.TM_av) FM_count = this.state.params.TM_av.count
        }
        if (this.state.params.Energy) {
          //console.log(this.state.params.Energy)
          if (this.state.params.Energy.groups) EnergyFeeder = this.state.params.Energy.groups.used.feeder
          if (this.state.params.Energy.groups) EnergyRobot = this.state.params.Energy.groups.used.robot
          if (this.state.params.Energy.av) EnergyFeederAv = this.state.params.Energy.av.feeder
          if (this.state.params.Energy.av) EnergyRobotAv = this.state.params.Energy.av.robot
        }
        if (this.state.params.Milk) {
          Milk = this.state.params.Milk.sum
          Milk_av = this.state.params.Milk_av.sum
          //Milk_count = this.state.params.Milk.count
          if (this.state.params.Milk.groups) {
            Milk_eff = this.state.params.Milk.groups.all.eff
            Milk_eff_av = this.state.params.Milk_av.groups.all.eff
            Milk_FEQ = this.state.params.Milk.FEQ
            Milk_FEQ_day = this.state.params.Milk.FEQ_old
            IOFC = this.state.params.Milk.groups.all.IOFC
            IOFC_av = this.state.params.Milk_av.groups.all.IOFC
          }
        }
      }
      let options = this.getOptionsChart()
      let labels = this.state.label1
      const data1 = {
        labels,
        datasets: this.state.data1,
      }
      labels = this.state.label2
      const data2 = {
        labels,
        datasets: this.state.data2,
      }
      let options2 = this.getOptionsChart()
      options2.scales.y1.display = false
      labels = this.state.label3
      const data3 = {
        labels,
        datasets: this.state.data3,
      }
      const data4 = {
        labels,
        datasets: this.state.data4,
      }
      let options3 = this.getOptionsChart()
      options3.scales.y.display = false
      options3.scales.y2.display = true
      let options4 = this.getOptionsChart()
      options4.scales.y.display = false
      options4.scales.yEff.display = true
      options4.scales.y1.display = true
      let i = 1
      let columns = this.createColumns()

      const em = parseFloat(getComputedStyle(document.body).getPropertyValue("font-size"))
      //console.log(this.state.windowWidth, this.state.windowHeight, em)
      let e1 = document.getElementById("dboard_1")
      let e2 = document.getElementById("dboard_2")
      let e3 = document.getElementById("dboard_3")
      let e4 = document.getElementById("dboard_4")
      let e5 = document.getElementById("dboard_5")
      let e6 = document.getElementById("dboard_6")
      let e7 = document.getElementById("dboard_7")
      let e8 = document.getElementById("dboard_8")
      let e9 = document.getElementById("dboard_9")
      let e10 = document.getElementById("dboard_10")
      let min = 23*em
      //console.log("--------------------")
      let a = Math.floor((this.state.windowWidth-em*4)/4-2*em)
      if ((this.state.windowWidth-em*4) < 4*(min+2*em)) a = Math.floor((this.state.windowWidth-em*6)/3-2*em)
      //console.log((this.state.windowWidth-em*25), "<", 4*(min+3.0*em), a)
      if ((this.state.windowWidth-em*6) < 3*(min+2*em)) a = Math.floor((this.state.windowWidth-em*9)/2-2*em)
      //console.log((this.state.windowWidth-em*27), "<", 3*(min+3.0*em), a)
      if ((this.state.windowWidth-em*6) < 2*(min+2*em)) a = 540
      //console.log((this.state.windowWidth-em*27), "<", 2*(min+3.0*em), a)
      if (a<min) a = min
      if (a>540) a = 540
      if (e1) e1.style.width = a +"px"
      if (e2) e2.style.width = a +"px"
      if (e3) e3.style.width = a +"px"
      if (e4) e4.style.width = a +"px"
      if (e5) e5.style.width = a +"px"
      if (e6) e6.style.width = a +"px"
      if (e7) e7.style.width = a +"px"
      if (e8) e8.style.width = a +"px"
      if (e9) e9.style.width = Math.floor(a*2+1.0*em) +"px"
      if (e10) e10.style.width = Math.floor(a*2+1.0*em) +"px"
      //console.log(this.state.params)
      //console.log(Milk_FEQ)
      return (
        <div>
          {this.state.params && this.state.params.Milk ?
          <div className ="home_dashboard_main">
            <div className="home_dashboard" id={"dboard_" + i++}>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr>
                  <td width="20%"><Link to="robot/milk"><img id="nav_milk" src={require("../img/euter.png")} alt={t('nav_milk')}  title={t('nav_milk')} width="50" onMouseOver={over} onMouseOut={out}/></Link></td>
                  <td><Link to="robot/milk"><b>{t("home_milk")}</b></Link></td>
                  <td><div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" class="infoimage" onClick={(value) => { this.setState({ showHelp: true, showHelpId: "help_homestat_milk", showPosition: [value.clientX, value.clientY], }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div></td>
                </tr>
              </tbody></table>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
              <tr><td>Ø 10 {t('days')}</td><td align="right" className="lastday">{Milk_av} l</td></tr>
                <tr><td>Ø {t('yesterday')}</td><td align="right" className="day10">{Milk} l</td></tr>
                <tr><td>{t('milkgroup_FEQ')}</td><td align="right" className="lastday">{Milk_FEQ < 1 || Milk_FEQ > 1.4 ? <span className="h_red">{Milk_FEQ}</span>: Milk_FEQ}</td></tr>
                {Milk_FEQ_day !== "" && Milk_FEQ !== undefined ? <tr><td className="small h_red">{t('milkgroup_FEQ_old_date')}</td><td align="right" className="small h_red">{Milk_FEQ_day}</td></tr> : null}
                {Milk_FEQ !== undefined ? <tr><td title={t('home_FEQ_img')}>{t('home_FEQ_img')} 10 {t('days')}</td><td align="right">
                  {Milk_FEQ > 1.4 ? <img src={require("../img/tacho/FEQ5.png")} alt=""  title="" width="70"/> :
                  Milk_FEQ > 1.35 ? <img src={require("../img/tacho/FEQ4.png")} alt=""  title="" width="70"/> :
                  Milk_FEQ > 1.15 ? <img src={require("../img/tacho/FEQ3.png")} alt=""  title="" width="70"/> :
                  Milk_FEQ > 1 ? <img src={require("../img/tacho/FEQ2.png")} alt=""  title="" width="70"/> :
                  <img src={require("../img/tacho/FEQ1.png")} alt=""  title="" width="70"/>}
                </td></tr> : null}
              </tbody></table>
            </div>
            <div className="home_stats" id={"dboard_" + i++}>
              <Line options={options2} data={data2} className="stats"/>
            </div>
          </div>
          : null }
          {this.state.params && this.state.params.Milk ?
          <div className ="home_dashboard_main">
            <div className="home_dashboard" id={"dboard_" + i++}>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr>
                  <td width="20%"><Link to="stats/efficiency"><img id="nav_efficiency" src={require("../img/efficiency.png")} alt={t('nav_efficiency')}  title={t('nav_efficiency')} width="50"/></Link></td>
                  <td><Link to="stats/efficiency"><b>{t("home_efficiency")}</b></Link></td>
                  <td><div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" class="infoimage" onClick={(value) => { this.setState({showHelp: true,  showHelpId: "help_homestat_FEff", showPosition: [value.clientX, value.clientY], }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div></td>
                </tr>
              </tbody></table>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
              <tr><td title={t('home_IOFC_long')}>{t("home_IOFC")} 10 {t('days')}</td><td align="right" className="lastday">{IOFC_av} €</td></tr>
                <tr><td title={t('home_IOFC_long')}>{t("home_IOFC")} {t('yesterday')}</td><td align="right" className="day10">{IOFC} €</td></tr>
                <tr><td title={t('home_eff_long')}>{t('home_eff')} 10 {t('days')}</td><td align="right" title={"[kg "+t('home_milk')+" / kg "+t('feed_T_short')+"]"} className="lastday">{Milk_eff_av >= 1.4 && Milk_eff_av <= 1.6 ? <span className="green">{Milk_eff_av}</span> : (Milk_eff_av >= 1.2 && Milk_eff_av <= 1.4) || (Milk_eff_av >= 1.6 && Milk_eff_av <= 1.8) ? <span className="yellow">{Milk_eff_av}</span> : Milk_eff_av < 1.2 || Milk_eff_av > 1.8 ? <span className="h_red">{Milk_eff_av}</span> : Milk_eff_av }</td></tr>
                <tr><td title={t('home_eff_long')}>{t('home_eff')} {t('yesterday')}</td><td align="right" title={"[kg "+t('home_milk')+" / kg "+t('feed_T_short')+"]"} className="day10">{Milk_eff >= 1.4  && Milk_eff <= 1.6 ? <span className="green">{Milk_eff}</span> : (Milk_eff >= 1.2 && Milk_eff <= 1.4) || (Milk_eff >= 1.6 && Milk_eff <= 1.8) ? <span className="yellow">{Milk_eff}</span> : Milk_eff < 1.2 || Milk_eff > 1.8 ? <span className="h_red">{Milk_eff}</span> : Milk_eff }</td></tr>
                <tr><td title={t('home_eff_long')}>{t('home_eff_img')} 10 {t('days')}</td><td align="right">
                  {Milk_eff_av > 2 ? <img src={require("../img/tacho/FEQ6.png")} alt=""  title="" width="70"/> :
                  Milk_eff_av > 1.8 ? <img src={require("../img/tacho/FEQ5.png")} alt=""  title="" width="70"/> :
                  Milk_eff_av > 1.6 ? <img src={require("../img/tacho/FEQ4.png")} alt=""  title="" width="70"/> :
                  Milk_eff_av > 1.4 ? <img src={require("../img/tacho/FEQ3.png")} alt=""  title="" width="70"/> :
                  Milk_eff_av > 1.2 ? <img src={require("../img/tacho/FEQ2.png")} alt=""  title="" width="70"/> :
                  Milk_eff_av > 1.0 ? <img src={require("../img/tacho/FEQ1.png")} alt=""  title="" width="70"/> :
                  <img src={require("../img/tacho/FEQ0.png")} alt=""  title="" width="70"/>}
                </td></tr>
              </tbody></table>
            </div>
            <div className="home_stats" id={"dboard_" + i++}>
              <Line options={options4} data={data4} className="stats"/>
            </div>
          </div>
          : null }
          {this.state.params && this.state.params.FM ?
          <div className ="home_dashboard_main">
            <div className="home_dashboard" id={"dboard_" + i++}>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr>
                  <td width="20%"><Link to="stats/feeds"><img id="nav_profile" src={require("../img/feeding.png")} alt={t('home_feeded')}  title={t('home_feeded')} width="50"/></Link></td>
                  <td><Link to="stats/feeds"><b>{t("home_feeded")}</b></Link></td>
                  <td><div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" class="infoimage" onClick={(value) => { this.setState({showHelp: true,  showHelpId: "help_homestat_feeded", showPosition: [value.clientX, value.clientY], }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div></td>
                </tr>
              </tbody></table>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr><td title={t('feed_FM')}>{t('feed_FM_short')} {t('yesterday')}</td><td align="right" title={t('yesterday')} className="lastday">{FM} kg</td></tr>
                <tr><td title={t('feed_FM')}>{t('feed_FM_short')} 10 {t('days')}</td><td align="right" title={FM_count+" Tage Schnitt"} className="day10">{FM_av} kg</td></tr>
                <tr><td title={t('feed_FM')}>{t('feed_FM_short')} {t('today')}</td><td align="right" title={t('today')} className="day10"><i>{FMToday} kg</i></td></tr>
                <tr><td title={t('feed_T')}>{t('feed_T_short')} {t('yesterday')}</td><td align="right" title={t('yesterday')} className="lastday">{TM} kg</td></tr>
                <tr><td title={t('feed_T')}>{t('feed_T_short')} 10 {t('days')}</td><td align="right" title={FM_count+" Tage Schnitt"} className="day10">{TM_av} kg</td></tr>
                <tr><td title={t('feed_T')}>{t('feed_T_short')} {t('today')}</td><td align="right" title={t('today')} className="day10"><i>{TMToday} kg</i></td></tr>
              </tbody></table>
            </div>
            <div className="home_stats" id={"dboard_" + i++}><Line options={options} data={data1} className="stats"/></div>
          </div>
          : null }
          {this.state.params && this.state.params.Energy ?
          <div className ="home_dashboard_main">
            <div className="home_dashboard" id={"dboard_" + i++}>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr>
                  <td width="20%"><Link to="stats/energy"><img id="nav_energy" src={require("../img/energy.png")} alt={t('nav_energy')}  title={t('nav_energy')} width="50"/></Link></td>
                  <td><Link to="stats/energy"><b>{t("home_energy")}</b></Link></td>
                  <td><div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" class="infoimage" onClick={(value) => { this.setState({showHelp: true,  showHelpId: "help_homestat_energy", showPosition: [value.clientX, value.clientY], }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div></td>
                </tr>
              </tbody></table>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr><td>{t("home_feeder") + " " + t('yesterday')}</td><td align="right" title={t("home_feeder") + " " + t('yesterday')} className="lastday">{EnergyFeeder} kWh</td></tr>
                <tr><td>{t("home_feeder") + " 10 " + t('days')}</td><td align="right" title={t("home_feeder") + " 10 " + t('days')} className="day10">{EnergyFeederAv} kWh</td></tr>
                <tr><td>{t("home_robot") + " " + t('yesterday')}</td><td align="right" title={t("home_robot") + " " + t('yesterday')} className="lastday">{EnergyRobot} kWh</td></tr>
                <tr><td>{t("home_robot") + " 10 " + t('days')}</td><td align="right" title={t("home_robot") + " 10 " + t('days')} className="day10">{EnergyRobotAv} kWh</td></tr>
              </tbody></table>
            </div>
            {this.state.data3 ?
            <div className="home_stats" id={"dboard_" + i++}>
              <Line options={options3} data={data3} className="stats"/>
            </div>
            : null }
          </div>
          : null }
          <div className ="home_dashboard_main">
            <div className="home_dashboard home_dashboard_statuslist" id={"dboard_" + i++}>
              <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
                <tr><td width="10%">
                  <Link to="profile/robots"><img id="nav_energy" src={require("../img/robots.png")} alt={t('nav_robot')}  title={t('nav_robot')} width="50"/></Link></td>
                  <td><Link to="profile/robots"><b>{t("robot_status")}</b></Link></td>
                  <td><div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" class="infoimage" onClick={(value) => { this.setState({showHelp: true,  showHelpId: "help_homestat_robots", showPosition: [value.clientX, value.clientY], }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div></td>
                </tr>
              </tbody></table>
              <div className="table_stats">
                <ReactTable
                  data={this.state.status}
                  columns={columns}
                  minRows = {0}
                  previousText= {'<'}
                  nextText= {'>'}
                  showPageJump= {true}
                  defaultPageSize = {20}
                  pageSizeOptions = {[10, 20, 50, 100]}
                  showPaginationBottom = {false}
                  useFlexLayout = {true}
                  sortable = {false}
                  getTrProps={(state, rowInfo, column, instance) => {
                    return {
                        //onDoubleClick: (e, t) => { if (rowInfo.index !== this.state.selected) { this.setState({ selected: rowInfo.index, }) } },
                        style: {
                          background: rowInfo.row.color === 1 ? "#DDFFDD" : rowInfo.row.color === 2 ?  "#FFFFDD" :  rowInfo.row.color === 3 ?  "#FFD0DD" : 'white',
                          //color: rowInfo &&  rowInfo.index === this.state.selected ? '#777777' : rowInfo.row.service_level === 2 ?  "#D00000" : 'black'
                        }
                    }
                  }}
                />
              </div>
            </div>
            <div className="home_dashboard home_error" id={"dboard_" + i++}>
            <table width="100%" cellSpacing="0"  cellPadding="0" border="0"><tbody>
              <tr>
                <td width="20%"><Link to="profile/error"><img id="nav_error" src={require("../img/error.png")} alt={t('home_errors')}  title={t('home_errors')} width="50"/></Link></td>
                <td><Link to="profile/error"><b>{t("home_errors")}</b></Link></td>
                <td><div className="right_side"><img id="info" src={require("./img/info.png")} alt="Info" class="infoimage" onClick={(value) => { this.setState({showHelp: true,  showHelpId: "help_homestat_error", showPosition: [value.clientX, value.clientY], }) } } onMouseOver={(e) => {e.currentTarget.src = require("./img/info_mouseover.png")}} onMouseOut={(e) => {e.currentTarget.src = require("./img/info.png")}} /></div></td>
              </tr>
            </tbody></table>
            {this.state.errors !== null ? this.state.errors.map((error, index) => (
              <div key={index} className="error_head_line">
                <div><div className="error_head_left">{error.time}</div>
                <div className="error_head_middle1">{error.error_level < 30 ? <span className="warning">{error.type}</span> : error.type} </div>
                <div className="error_head_middle2"><span className="warning"><span dangerouslySetInnerHTML={{__html: error.message}}></span></span> </div>
                <div className="error_head_right" align="right">{error.robot_name}</div></div>
              </div>
            )): null}
          </div>
          </div>
          {this.state.showHelp && <Info id={this.state.showHelpId} position = {this.state.showPosition} closeDiv={() => { this.setState({ showHelp: false, }) }} reset={() => { this.setState({ showHelpId: null, }) }}/>}
        </div>
      )
    }
  }
}

export default HomeStat